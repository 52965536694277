<template>
  <div>
    <HeaderNavHomepage />
    <main>
      <div class="main-info">
        <h5>INSIGHTS</h5>
        <h1>Ang Walang Kwentang Podcast</h1>
        <p>Antoinette Jadaone & JP Habac</p>
      </div>
      <div class="black-bg"></div>
    </main>

    <div class="blog-details">
      <div class="blog-details-info">
        <section>
          <div class="details">
            <i class="fa-solid fa-podcast svg-icon"></i>
            <p class="subtitle">Top 5 Podcast in the Philippines on Spotify.</p>
          </div>
          <div class="details">
            <i class="fa-solid fa-film svg-icon"></i>
            <p class="subtitle">Pioneering film directors.</p>
          </div>
          <div class="details">
            <i class="fa-solid fa-people-group svg-icon"></i>
            <p class="subtitle">Over 500 fan subscribers.</p>
          </div>
          <div class="details">
            <i class="fa-solid fa-money-bill-trend-up svg-icon"></i>
            <p class="subtitle">Over ₱ 1,000,000 in earnings.</p>
          </div>
        </section>

        <!-- THE GOAL -->
        <section>
          <h5 style="margin-top: 5rem" class="title">THE GOAL</h5>
          <p class="subtitle">
            AWKP’s goal is to strengthen their growing community of avid
            supporters and to offer a way to easily sell their long-awaited
            merch boxes.
          </p>
        </section>

        <!-- THE STORY -->
        <section>
          <h5 class="title">THE STORY</h5>
          <p class="subtitle">
            Ang Walang Kwentang Podcast (AWKP), or the senseless podcast in
            english, is one of the biggest local podcasts in the Philippines.
            Filmmakers and real-life friends Antoinette Jadaone and JP Habac let
            people eavesdrop (and sometimes join) their hilarious and insightful
            conversations about pop culture, love, and life. They are regarded
            as one of the top local podcasts in the Philippines and they also
            have an active community of supporters.
          </p>
        </section>

        <!-- TWO IMAGES -->
        <section
          style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            margin-top: 2rem;
          "
          class="images"
        >
          <img src="/img/awkp-img1-min.png" alt="" />
          <img src="/img/awkp-img2-min.png" alt="" />
        </section>

        <!-- Starting off with Balato -->
        <section>
          <h5 class="title">Starting off with Balato</h5>
          <p class="subtitle">
            A week before the launch of their merch boxes, they wanted to test
            out the website by initially offering a tipping jar. They named it
            “Balato,” or share of money given by the winner (in gambling or
            business deal). It also resonated well with their target audience.
            <br />
            <br />
            They started with a single payment of Php 100, also allowing their
            fans to do multiple orders - making it 100-peso increments. And with
            their solid fanbase, we saw people from all over the world donating
            up to Php 2,000 in just one go.
            <br />
            <br />
            This was a good way for them to warm up their website and
            familiarize their audience with their new dot com website. And since
            their launch, they would always dedicate a segment about their
            website on all their podcast episodes.
          </p>
        </section>

        <!-- The launch of their much-awaited Starter Pück -->
        <section>
          <h5 class="title">The launch of their much-awaited Starter Pück</h5>
          <p class="subtitle">
            Few days after their Gcash went live, AWKP finally dropped their
            long-awaited merch boxes - Box 1: Eto Na Nga Bax (Php 1,399) and Box
            2: The Second Box (Php 1,199).
            <br />
            <br />
            They scheduled the launched around the same time as payday and also
            announced it when they released a new podcast episode. This strategy
            helped amplify the new that the merch boxes are finally available
            for purchase on their own website.
          </p>
        </section>

        <!-- Pück, Pück, Ganon! : The Subscriptions Premiere -->
        <section>
          <h5 class="title">Pück, Pück, Ganon! : The Subscriptions Premiere</h5>
          <p class="subtitle">
            More than a month after the soft launch of their website, AWKP
            launched their subscription offers - VIP (Very Important Pücker) and
            VVIP (VurVery Important Pücker).
            <br />
            <br />
            Both tiers included monthly shoutouts in their podcast episodes and
            access to Ang Walang Kwentang Podcast Sosyal Clüb (their private
            Discord server).
          </p>
        </section>

        <!-- Discord -->
        <section>
          <img class="images" src="/img/awkp-people-min.png" alt="" />
          <p class="subtitle">
            Discord played a huge role in strengthening their already existing
            AWKP community. It gave them a medium to chat with fellow fans as
            well as with Direk Tonet and Direk JP, making the subscriptions
            worth it.
            <br />
            <br />
            As a result, their private Discord server got to connect all other
            members and loyal fans of AWKP, forming deeper bonds among members.
            They have a 24/7 VVIP Tambayan or voice channel, where any member
            could join.
          </p>
        </section>
      </div>
    </div>

    <MainCCFooter />
  </div>
</template>

<script>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainCCFooter from '@/components/MainCCFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

export default {
  name: 'AngWalangKwentangPodcast',
  components: {
    HeaderNavHomepage,
    MainCCFooter,
  },

  setup() {
    useHead(
      buildPageMeta({
        title:
          'Ang Walang Kwentang Podcast | Ecommerce Technology for Creators & Communities',
        description:
          'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
        url: 'https://www.helixpay.ph/angwalangkwentangpodcast',
        image: 'https://www.helixpay.ph/img/awkp-min.png',
      })
    );
  },
};
</script>

<style scoped>
section {
  max-width: 50rem;
  margin: auto;
  padding: 0 2rem;
}

main {
  background-image: url('/img/awkp-pink.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30% 70%;
  background-attachment: inherit;
}

.main-info {
  max-width: 50rem;
  margin: auto;
  padding: 10rem 2rem;
  color: white;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
}

.main-info h5 {
  font-size: 1rem;
  font-weight: 700;
}

.main-info h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

.main-info p {
  font-size: 1rem;
}

.details {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.details i {
  text-align: center;
  width: 6%;
  margin-right: 10px;
  opacity: 0.5;
}

.details p {
  margin-top: 1rem;
}

.svg-icon {
  font-size: 2rem;
  margin-top: 0;
  padding-top: 0;
  opacity: 1;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #ff5f8f;
  margin-top: 3rem;
}

.subtitle {
  line-height: 2;
  font-weight: 500;
  opacity: 0.5;
}

.images {
  width: 100%;
  margin-bottom: 2rem;
}

.images img {
  width: 100%;
}

.blog-details {
  width: 100%;
}

.blog-details-info {
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media only screen and (max-width: 650px) {
  .details {
    gap: 2rem;
  }
}
</style>
